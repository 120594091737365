import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { AppRouter } from './routes/AppRouter'
import { UserProvider } from './context/UserProvider'

// Estilos
import './index.css'

createRoot(document.getElementById('root')).render(
    <StrictMode>
        <UserProvider>
            <AppRouter />
        </UserProvider>
    </StrictMode>,
)
